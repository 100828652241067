/***
	main.js
	Entrée principale des scripts
***/


/**
	IMPORTS
**/
// Local
// import twentytwenty from "./plugins/twentytwenty.js";

// node_modules
// import "lazysizes";
// import "lazysizes/plugins/unveilhooks/ls.unveilhooks.js";
// import baguetteBox from "baguettebox.js";


/**
	Components
**/
import {component} from "./components/_component"


/**
	Classes
**/
import {MyClass} from "./classes/_myClass"
import {FixMenu} from "./classes/FixMenu"


/** DOM ready **/
document.addEventListener("DOMContentLoaded", function(event) {

	// Executer les components
	component();

	// Instancier les classes
	const a = new MyClass();

	const b = new FixMenu();

});
