class FixMenu {
    constructor() {
      this.dom = {
        header: document.querySelector(".header__navbar")
      };
  
      this.init();
      this.onScroll(window.scrollY || document.documentElement.scrollTop);
    }
  
    init() {
      // Référence: http://www.html5rocks.com/en/tutorials/speed/animations/
      let lastScrollPosition = 0;
      let ticking = false;
  
      window.addEventListener("scroll", e => {
        lastScrollPosition = window.scrollY || document.documentElement.scrollTop;
  
        if (!ticking) {
          window.requestAnimationFrame(() => {
            this.onScroll(lastScrollPosition);
            ticking = false;
          });
        }
        ticking = true;
      });
    }
  
    onScroll(_scrollPos) {
      if (_scrollPos > 0) {
        this.dom.header.classList.add("is-scrolling");
      } else {
        this.dom.header.classList.remove("is-scrolling");
      }
    }
  }

  export { FixMenu };